import type { ComputedRef, Ref } from 'vue';

import type { AuctionItemEntity, AuctionListingItemEntity } from '~/apiClient';
import { AuctionState } from '~/apiClient';

type AuctionStatus = {
    statusSectionColor: ComputedRef<string | undefined>;
    title: ComputedRef<string | undefined>;
    titleColor: ComputedRef<string | undefined>;
    titleAnnotation: ComputedRef<string | undefined>;
    titleAnnotationColor: ComputedRef<string | undefined>;
    priceColor: ComputedRef<string | undefined>;
    showReserveNotice: ComputedRef<boolean>;
    isPriceHidden: ComputedRef<boolean>;
    isFeeNoticeHidden: ComputedRef<boolean>;
    isCountdownVisible: ComputedRef<boolean>;
};

export default function useAuctionStatusAdapter(auction: Ref<AuctionItemEntity | AuctionListingItemEntity>): AuctionStatus {
    const { tt } = useTypedI18n();
    const { logWarn } = useLogs();

    const negotiationInProgress = computed(
        () => auction.value.state === AuctionState.Closed && auction.value.userMeta?.maxBidder && !auction.value.userMeta?.reservePriceReached
    );
    const showReserveNotice = computed(() => auction.value.userMeta?.reservePriceReached === false);
    const isPriceHidden = computed(() => auction.value.state === AuctionState.Canceled);
    const isFeeNoticeHidden = computed(() => isPriceHidden.value || auction.value?.state === AuctionState.Draft);
    const isCountdownVisible = computed(() => auction.value.state === AuctionState.Pending || auction.value.state === AuctionState.Running);

    const statusSectionColor = computed(() => {
        if (auction.value.state === AuctionState.Running) {
            if (auction.value.userMeta?.maxBidder) return 'bg-green-50';
            if (auction.value.userMeta?.outBid) return 'bg-amber-50';

            return 'bg-blue-50';
        }

        if (auction.value.state === AuctionState.Closed && auction.value.userMeta?.maxBidder) return 'bg-green-50';

        if ((<string[]>[AuctionState.Closed, AuctionState.Canceled, AuctionState.Draft, AuctionState.Pending]).includes(auction.value.state))
            return 'bg-neutral-100';

        logWarn(`AdpAuctionPanel: No style defined for auction state ${auction.value.state}`, { auctionId: auction.value.id });
    });

    const title = computed(() => {
        switch (auction.value.state) {
            case AuctionState.Draft:
                return tt('auction.auctionPanel.reservePrice');
            case AuctionState.Pending:
                return tt('auction.auctionPanel.startingPrice');
            case AuctionState.Running:
                return tt('auction.auctionPanel.current');
            case AuctionState.Canceled:
                return tt(`auction.auctionPanel.state.${AuctionState.Canceled}`);
            case AuctionState.Closed:
                return auction.value.userMeta?.maxBidder && auction.value.userMeta?.reservePriceReached
                    ? tt('auction.auctionPanel.wonAuction')
                    : tt(`auction.auctionPanel.state.${AuctionState.Closed}`);
        }
    });
    const titleColor = computed(() => {
        if (auction.value.state === AuctionState.Closed && auction.value.userMeta?.maxBidder) return 'text-green-600';
    });

    const titleAnnotation = computed(() => {
        if (auction.value.state === AuctionState.Running) {
            if (auction.value.userMeta?.maxBidder) return tt('auction.auctionPanel.maxBidder');
            if (auction.value.userMeta?.outBid) return tt('auction.auctionPanel.outBid');
        }

        if (negotiationInProgress.value) return tt('auction.auctionPanel.negotiationInProgress');
    });
    const titleAnnotationColor = computed(() => {
        if (auction.value.state === AuctionState.Running) {
            if (auction.value.userMeta?.maxBidder) return 'text-green-600';
            if (auction.value.userMeta?.outBid) return 'text-yellow-600';
        }

        if (negotiationInProgress.value) return 'text-green-600';
    });

    const priceColor = computed(() => {
        if (auction.value.state === AuctionState.Canceled) return 'text-black';
        if (auction.value.state === AuctionState.Closed) return auction.value.userMeta?.maxBidder ? 'text-green-800' : 'text-black';

        return 'text-primary';
    });

    return {
        statusSectionColor,
        title,
        titleColor,
        titleAnnotation,
        titleAnnotationColor,
        priceColor,
        showReserveNotice,
        isPriceHidden,
        isFeeNoticeHidden,
        isCountdownVisible,
    };
}
