// Density is not directly managed here. If an image with density x{n} is requested, getImage is called with size * {n}.
// N.B. Due to the nature of the images used, almost always on fluid layouts with extended ranges, it may sometimes be necessary to force
// only the "x1" density, in order to limit the size of the image used on mobile.

import type { ProviderGetImage } from '@nuxt/image';
import { joinURL } from 'ufo';

enum ImageSize {
    Xs = 'xs',
    Sm = 'sm',
    Lg = 'lg',
    Xl = 'xl',
}

const imageSizesToWidth = Object.entries({
    [ImageSize.Xs]: 240,
    [ImageSize.Sm]: 480,
    [ImageSize.Lg]: 1280,
    [ImageSize.Xl]: 2560,
}).sort(([, widthA], [, widthB]) => widthA - widthB) as [ImageSize, number][];

export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
    const baseURL = useRuntimeConfig().public.mediaBaseUrl;
    const { width } = modifiers;
    const size: ImageSize = width ? imageSizesToWidth.find(([, _width]) => width <= _width)?.[0] ?? imageSizesToWidth.at(-1)![0] : ImageSize.Lg;

    return {
        url: joinURL(baseURL, `${src}_${size}.webp`),
    };
};
